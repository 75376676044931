/*
Import the jQuery
*/

import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

/*
Import the Tabler Js with Demo theme
*/
import '../../node_modules/@tabler/core/dist/js/tabler';
import '../../node_modules/@tabler/core/src/js/demo-theme';

//import '../../vendor/takielias/tablar-kit/resources/js/plugins/load.js';
